<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">

      </div>
      <a class="navbar-brand" href="{{getPath()}}"> {{getTitle()}}</a>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <form class="navbar-form">
        <div class="input-group no-border">
        </div>
      </form>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#" (click)="logout()">
            <i class="material-icons">exit_to_app</i>
            <p>
              <span class="d-lg-none d-md-block">Logout</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

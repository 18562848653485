<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="">
            Synked
          </a>
        </li>
  
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
      {{test | date: 'yyyy'}}, Synked
    </div>
  </div>
</footer>

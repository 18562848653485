<h2 mat-dialog-title>Selecionar Mensagem de WhatsApp</h2>

<mat-dialog-content>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Escolha uma Mensagem</mat-label>
    <mat-select [(value)]="selectedMessageId" (selectionChange)="onMessageSelect($event)">
      <mat-option *ngFor="let message of whatsappMessages" [value]="message.id">
        {{ message.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedMessageText" class="message-preview">
    <h4>Conteúdo da Mensagem:</h4>
    <p>{{ selectedMessageText }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="dialogRef.close()">Cancelar</button>
  <button mat-button color="primary" (click)="confirmSelection()" [disabled]="!selectedMessageText">Confirmar</button>
</mat-dialog-actions>



<style>
  .dialog-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>

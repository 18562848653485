import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { RoleGuard } from './core/guards/role-guard.service';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/pages/login',
    pathMatch: 'full',
  },  
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tecnicos',
        loadChildren: () => import('./components/technician/technician.module').then(m => m.TechnicianModule),
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRoles: ['USER', 'EMPLOYEE', 'ADMIN'] }
      },
      {
        path: 'chamados',
        loadChildren: () => import('./components/tickets/tickets.module').then(m => m.TicketModule),
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRoles: ['USER', 'EMPLOYEE', 'ADMIN'] }
      },
      {
        path: 'clientes',
        loadChildren: () => import('./components/clientinfo/clientinfo.module').then(m => m.ClientInfoModule),
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRoles: ['EMPLOYEE', 'ADMIN'] }
      },
      {
        path: 'profile',
        loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard, RoleGuard],
        data: { expectedRoles: ['ADMIN'] }

      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }
    ]
  }
];

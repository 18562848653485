import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface WhatsappMessage {
  id: number;
  title: string;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class WhatsappMessageService {

    private baseUrl: string = environment.baseUrl;
    private apiUrl = `${this.baseUrl}api/v1/whatsapp-messages`;

  constructor(private http: HttpClient) {}

  createMessage(message: WhatsappMessage): Observable<WhatsappMessage> {
    return this.http.post<WhatsappMessage>(this.apiUrl, message);
  }

  getAllMessages(): Observable<WhatsappMessage[]> {
    return this.http.get<WhatsappMessage[]>(this.apiUrl);
  }

  updateMessage(id: number, message: WhatsappMessage): Observable<WhatsappMessage> {
    return this.http.put<WhatsappMessage>(`${this.apiUrl}/${id}`, message);
  }

  deleteMessage(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private baseUrl: string = environment.baseUrl;

  private base = `${this.baseUrl}api/v1/auth`;

  constructor(private httpClient: HttpClient, private router: Router) { 
    const storedProfilePicture = localStorage.getItem('profilePicture');
    this._profilePicture = new BehaviorSubject<string>(storedProfilePicture || '');
  }
  
  private _profilePicture = new BehaviorSubject<string>('');

  get profilePicture() {
    return this._profilePicture.asObservable();
  }

  updateProfilePicture(newProfilePicture: string) {
    localStorage.setItem('profilePicture', newProfilePicture);
    this._profilePicture.next(newProfilePicture);
    }

  login(email: string, password: string): Observable<any> {
    const body = {
      email,
      password
    };
    return this.httpClient.post(`${this.base}/signin`, body);
  }

  isLoggedIn(): Observable<any> {
    const token = localStorage.getItem('jwt');
    if (!token) {
      return throwError('No token found');
    }
    const headers = { 'Authorization': token };
    return this.httpClient.post<any>(`${this.base}/validateToken`, {}, { headers })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getUserName(): string {
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    return `${firstName} ${lastName}`;
  }

  getFirstName(): string {
    const firstName = localStorage.getItem('firstName');
    return `${firstName}`;
  }

  getLastName(): string {
    const lastName = localStorage.getItem('lastName');
    return `${lastName}`;
  }

  getUserEmail(): string {
    return localStorage.getItem('email');
  }
  
  getProfilePicture(): string {
    return localStorage.getItem('profilePicture');
  }

  updatePassword(oldPassword: string, newPassword: string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const headers = { 'Authorization': token };
    const body = {
      oldPassword,
      newPassword
    };
    return this.httpClient.post(`${this.base}/updatePassword`, body, { headers });
  }

  uploadProfilePicture(file: File): Observable<any> {
    const token = localStorage.getItem('jwt');
    const headers = { 'Authorization': `Bearer ${token}` };
    const formData: FormData = new FormData();
    const uniqueName = uuidv4() + '.' + file.name.split('.').pop();
  
    formData.append('file', file, uniqueName);
    return this.httpClient.post(`${this.base}/uploadProfilePicture`, formData, { headers });
  }

  getUserRole() : string {
    const token = localStorage.getItem('jwt');
  if (!token) {
    return null;
  }
  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  return tokenPayload.roles[0];
  }

  getUserId() : string {
    const token = localStorage.getItem('jwt');
  if (!token) {
    return null;
  }
  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  return tokenPayload.userId;
  }

  public signup(signUpData: any, userType: string): Observable<any> {
    return this.httpClient.post(`${this.base}/signup?userType=${userType}`, { ...signUpData });
  }
  
  logout(): void {
    localStorage.removeItem('jwt');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('profilePicture');

    this._profilePicture.next('');

    this.router.navigate(['/pages/login']);
  }

  forgotPassword(email: string): Observable<any> {
    return this.httpClient.post(`${this.base}/forgot-password`, { email });
  }

  resetPassword(token: string, newPassword: string): Observable<any> {
    return this.httpClient.post(`${this.base}/reset-password`, { token, newPassword });
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WhatsappMessageService } from 'src/app/services/whatsapp.message.service';

interface WhatsappMessage {
  id: number;
  title: string;
  text: string;
}

@Component({
  selector: 'app-select-whatsapp-message-dialog',
  templateUrl: './select-whatsapp-message-dialog.component.html',
  styleUrls: ['./select-whatsapp-message-dialog.component.css']
})
export class SelectWhatsappMessageDialogComponent implements OnInit {
  whatsappMessages: WhatsappMessage[] = [];
  selectedMessageId: number | null = null;
  selectedMessageText: string | null = null;
  isLoading: boolean = true;

  constructor(
    private whatsappMessageService: WhatsappMessageService,
    public dialogRef: MatDialogRef<SelectWhatsappMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.loadMessages();
  }

  loadMessages() {
    this.whatsappMessageService.getAllMessages().subscribe({
      next: (messages) => {
        this.whatsappMessages = messages;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  onMessageSelect(event: any) {
    const selectedMessage = this.whatsappMessages.find(msg => msg.id === event.value);
    this.selectedMessageText = selectedMessage ? selectedMessage.text : null;
  }

  confirmSelection() {
    this.dialogRef.close(this.selectedMessageText);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/services/authentication.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

declare const $: any;

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    roles?: string[];

}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    roles?: string[];

}

const ADMIN_ROLE = 'ADMIN';
const EMPLOYEE_ROLE = 'EMPLOYEE';
const USER_ROLE = 'USER';

const ADMIN_ROLES = ['ADMIN'];
const ADMIN_EMPLOYEE_ROLES = [ADMIN_ROLE, EMPLOYEE_ROLE];
const ALL_ROLES = [USER_ROLE, ADMIN_ROLE, EMPLOYEE_ROLE];

export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
},
{
    path: '/chamados',
    title: 'Chamados',
    type: 'sub',
    icontype: 'assignment',
    collapse: 'chamados',
    children: [
        { path: 'list', title: 'Listar Chamados', ab: 'LC', roles: ALL_ROLES },
        { path: 'add', title: 'Adicionar Chamados', ab: 'AC', roles: ADMIN_EMPLOYEE_ROLES},
    ],
    roles: ALL_ROLES

},
{
    path: '/tecnicos',
    title: 'Técnicos',
    type: 'sub',
    icontype: 'person',
    collapse: 'tecnicos',
    children: [
        { path: 'list', title: 'Listar Técnicos', ab: 'LT', roles: ALL_ROLES },
        { path: 'add', title: 'Adicionar Técnicos', ab: 'AT', roles: ADMIN_EMPLOYEE_ROLES },
    ],
    roles: ALL_ROLES
},
{
    path: '/clientes',
    title: 'Clientes',
    type: 'sub',
    icontype: 'person',
    collapse: 'clientes',
    children: [
        { path: 'list', title: 'Listar Clientes', ab: 'LC', roles: ADMIN_EMPLOYEE_ROLES  },
        { path: 'add', title: 'Adicionar Clientes', ab: 'LC', roles: ADMIN_EMPLOYEE_ROLES  },
    ],
    roles: ADMIN_EMPLOYEE_ROLES
},
{
    path: '/admin',
    title: 'Administração',
    type: 'sub',
    icontype: 'security',
    collapse: 'admin',
    children: [
        { path: 'painel', title: 'Painel de administração', ab: 'PA', roles: ADMIN_ROLES  },
        { path: 'users', title: 'Painel de usuarios', ab: 'PU', roles: ADMIN_ROLES  },

        { path: 'export', title: 'Exportar em Excel', ab: 'EX', roles: ADMIN_ROLES  },
        { path: 'list', title: 'Listar Logs', ab: 'LG', roles: ADMIN_ROLES  },
        { path: 'param', title: 'Parametrização de Status', ab: 'PS', roles: ADMIN_ROLES  },
        { path: 'paramMessage', title: 'Parametrização de Mensagens', ab: 'PM', roles: ADMIN_ROLES  },
    ],
    roles: ADMIN_ROLES
},

];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public username: string = '';
    public menuItems: any[];
    ps: any;
    profilePictureUrl: string;
    profilePicture: string;
    private profilePictureSubscription: Subscription;
    public isProduction: boolean = environment.production;

    constructor(private authService: AuthenticationService) {
        this.username = this.authService.getUserName();
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    ngOnInit() {
        const userRole = this.authService.getUserRole();

        this.menuItems = ROUTES.map(menuItem => {
            if (!menuItem.roles || menuItem.roles.includes(userRole)) {
                if (menuItem.children) {
                    menuItem.children = menuItem.children.filter(childItem => {
                        return !childItem.roles || childItem.roles.includes(userRole);
                    });
                }
                return menuItem;
            }
            return null;
        }).filter(item => item);

        this.profilePictureSubscription = this.authService.profilePicture.subscribe(
            newProfilePicture => {
                this.profilePicture = newProfilePicture;
            }
        );

        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        }
    }

    ngOnDestroy() {
        this.profilePictureSubscription.unsubscribe();
    }

    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    expandOrCollapseMenu(id) {
        let parent = document.getElementById(id + "-p");
        let child = document.getElementById(id);
        parent.ariaExpanded = parent.ariaExpanded === "true" ? "false" : "true";
        child.style.height = child.style.height === "0px" || child.style.height === "" ? "100%" : "0";
    }
}

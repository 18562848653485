<div mat-dialog-title>
    <h3>{{title}}</h3>
</div>

<div mat-dialog-content>
    <p [innerHTML]="message"></p>
</div>

<div mat-dialog-actions class="dialog-actions">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button color="primary" [mat-dialog-close]="true">Confirmar</button>
</div>

<style>
  .dialog-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>

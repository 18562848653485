<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">

    </div>
  </a>
  <a href="#/dashboard" class="simple-text logo-normal">
    <img *ngIf="isProduction" src="/assets/img/logo/logo.png" class="logo-image" alt="Synked Logo"/>
    
    <span *ngIf="!isProduction" class="homologacao-text">homologacao</span>
  </a>
</div>


<div class="sidebar-wrapper">

  <div class="user">
    <div class="photo">
      <img [src]="profilePicture || './assets/img/default-avatar.png'" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          {{username}}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <li class="nav-item">
            <a routerLink="profile" class="nav-link">
              <span class="sidebar-mini">MP</span>
              <span class="sidebar-normal">Meu Perfil</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>

      <a data-toggle="collapse" href="#{{menuitem.collapse}}" id="{{menuitem.collapse}}-p"
        *ngIf="menuitem.type === 'sub'" (click)="expandOrCollapseMenu(menuitem.collapse)" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}<b class="caret"></b></p>
      </a>

      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!-- Nao remover -->
    <div><br></div>
    <div><br></div>
    <div><br></div>
    <!-- Nao remover -->

  </ul>

</div>
<style>
  .homologacao-text {
  color: red;
  font-weight: bold;
  font-size: 1.2em; /* Ajuste conforme necessário */
}
</style>

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(isLoggedIn => {
        if (isLoggedIn && isLoggedIn.httpStatus === 'OK') {
          return true;
        } else {
          this.router.navigate(['pages/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }),
      catchError(error => {
        this.router.navigate(['pages/login'], { queryParams: { returnUrl: state.url } });
        return of(false);
      })
    );
  }
}
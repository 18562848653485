import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './message-dialog.component.html',
})
export class MessageDialogComponent {
 title: string;
 message: string;
 errorMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message;
    this.errorMessage = data.errorMessage;
  }
}

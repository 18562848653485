import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SelectWhatsappMessageDialogComponent } from './select-whatsapp-message-dialog/select-whatsapp-message-dialog.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    MessageDialogComponent,
    SelectWhatsappMessageDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    ConfirmDialogComponent,
    MessageDialogComponent,
    SelectWhatsappMessageDialogComponent,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {}
